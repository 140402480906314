import React from "react";
import { FaCheck } from "react-icons/fa";
import { motion } from "framer-motion";
import whyUranus from "../assets/whyUranus.png";

const WhyUranus = () => {
  const reasons = [
    { title: "Core Banking Solution (CBS) for 30+ Institutions" },
    { title: "Client-Focused Approach" },
    { title: "Innovative Solutions" },
    { title: "Commitment to Excellence" },
  ];

  return (
    <motion.section
      className="mt-10 px-4 md:px-10 lg:px-20 py-10 relative"
      style={{
        backgroundImage:
          "url('https://img.freepik.com/free-vector/white-background-with-blue-tech-hexagon_1017-19366.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Semi-transparent overlay */}
      <div className="absolute inset-0 bg-gray-100 bg-opacity-80"></div>

      {/* Content Container */}
      <div className="relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-6 items-center">
          {/* Text Section */}
          <div>
            <motion.div
              className="flex flex-col"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <h1 className="font-semibold text-primary text-xl md:text-2xl  lg:text-3xl text-center mb-2">
                Why Uranus Tech Nepal?
              </h1>
              <p className="text-start text-gray-600 text-sm sm:text-base md:text-md mb-2">
                Our team of seasoned experts brings deep industry knowledge and
                a client-focused approach, working closely with you to deliver
                innovative, high-quality solutions that exceed expectations. By
                leveraging the latest technologies and best practices, we ensure
                excellence while meeting your deadlines and budget.
              </p>
            </motion.div>

            {reasons.map((reason, index) => (
              <motion.div
                key={index}
                className="relative overflow-hidden"
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.3 * index, duration: 0.5 }}
              >
                <h2 className="text-sm sm:text-base md:text-md lg:text-lg mb-2 mt-2 flex items-center gap-2 text-start text-gray-600">
                  <FaCheck className="text-primary text-base sm:text-lg" />{" "}
                  {reason.title}
                </h2>
              </motion.div>
            ))}
          </div>

          {/* Image Section */}
          <motion.div
            className="relative w-full flex justify-center mx-auto"
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img
              src={whyUranus}
              alt="About Us"
              className="w-[70%] sm:w-[80%]  lg:w-[95%] h-auto object-contain transition-transform duration-500 transform hover:scale-110 rounded-xl"
            />
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default WhyUranus;
