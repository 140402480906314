import { useEffect, useRef } from "react";

function MouseCircle() {
  const circleRef = useRef(null);
  const timeoutRef = useRef(null);
  const mousePos = useRef({ x: 0, y: 0 });
  const circlePos = useRef({ x: 0, y: 0 });
  const frameRef = useRef(null);

  useEffect(() => {
    const circle = circleRef.current;
    if (!circle) return;

    const handleMouseMove = (e) => {
      // Update the target mouse position
      mousePos.current = { x: e.clientX, y: e.clientY };

      // Reset "stopped" state
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Add thicker border when following
      circle.classList.add("w-4", "h-4");
      circle.classList.remove("w-8", "h-8");

      // Set timeout for "stopped" state
      timeoutRef.current = setTimeout(() => {
        circle.classList.remove("w-4", "h-4");
        circle.classList.add("w-8", "h-8");
      }, 500);
    };

    // Animation loop for smooth following with weight/inertia
    const animateCircle = () => {
      // Calculate the weighted movement (easing)
      const ease = 0.1; // Lower = more weight/slower follow

      // Calculate new position with easing
      circlePos.current.x += (mousePos.current.x - circlePos.current.x) * ease;
      circlePos.current.y += (mousePos.current.y - circlePos.current.y) * ease;

      // Apply the new position
      circle.style.left = `${circlePos.current.x}px`;
      circle.style.top = `${circlePos.current.y}px`;

      // Continue the animation loop
      frameRef.current = requestAnimationFrame(animateCircle);
    };

    // Start animation loop
    frameRef.current = requestAnimationFrame(animateCircle);

    // Add mouse move listener
    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (frameRef.current) cancelAnimationFrame(frameRef.current);
    };
  }, []);

  return (
    <div
      ref={circleRef}
      className="hidden lg:block fixed pointer-events-none z-50 border-4 border-[#6ec8d6] rounded-full w-4 h-4 transition-all duration-75 ease-out"
      style={{
        transform: "translate(-50%, -50%)", // Center the element on the cursor point
        left: 0,
        top: 0,
      }}
    />
  );
}

export default MouseCircle;
