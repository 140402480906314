import React from "react";
import logo from "../assets/logo.png";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const handelSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="px-4 md:px-10 lg:px-20 py-10 relative"
      style={{
        backgroundImage:
          "url('https://img.freepik.com/free-vector/white-abstract-background_23-2148882948.jpg?t=st=1743674612~exp=1743678212~hmac=29fd3af2822312a7d03e05cc3a2c9d34cfa060f878e16e4e1c93df927253dc52&w=1380')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-gray-950 bg-opacity-90"></div>
      <div className="relative z-10 w-full">
        {/* Logo and Heading */}
        <div className="flex flex-col sm:flex-row justify-between items-center lg:items-start space-y-4 sm:space-y-0">
          <div className="flex flex-col sm:flex-row items-center md:space-x-3 text-center sm:text-left">
            <img
              src={logo}
              alt="Uranus Tech Nepal"
              className="h-16 w-20 mb-2 sm:mb-0"
            />
            <h1 className="font-bold text-white text-xl sm:text-2xl lg:text-3xl">
              Uranus Tech Nepal
            </h1>
          </div>
        </div>

        {/* Grid Sections */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8 mt-8 text-white ">
          {/* Services */}
          <div className="text-md md:text-lg lg:text-xl">
            <h2 className="font-bold text-secondary mb-2 ">Services</h2>
            <ul className="space-y-2 font-light text-sm sm:text-md lg:text-base ">
              <li className="hover:text-secondary hover:underline">
                <NavLink to="/products/CBS">
                  Core Banking Solutions (CBS)
                </NavLink>
              </li>
              <li className="hover:text-secondary hover:underline">
                <NavLink to="/products">Web Development</NavLink>
              </li>
              <li className="hover:text-secondary hover:underline">
                <NavLink to="/products">Mobile App Development</NavLink>
              </li>
              <li className="hover:text-secondary hover:underline">
                <NavLink to="/products">Bulk SMS</NavLink>
              </li>
              <li className="hover:text-secondary hover:underline">
                <NavLink to="/products">Cloud Services</NavLink>
              </li>
              <li className="hover:text-secondary hover:underline">
                <NavLink to="/products">IT Consulting</NavLink>
              </li>
            </ul>
          </div>

          {/* Contact Us */}
          <div className="text-md md:text-lg lg:text-xl">
            <h2 className="font-bold text-secondary mb-2">Contact Us</h2>
            <ul className="space-y-2 font-light text-sm sm:text-md lg:text-base">
              <li className="flex items-center hover:text-primary transition duration-200">
                <FaEnvelope />
                <a
                  href="mailto:info@uranustechnepal.com"
                  className="ml-2 hover:underline"
                >
                  info@uranustechnepal.com
                </a>
              </li>
              <li className="flex items-center hover:text-primary transition duration-200">
                <FaPhoneAlt className="mr-2" />
                <a href="tel:+977015244670" className="hover:underline">
                  +977-01-5244670
                </a>
              </li>
              <li className="flex items-center hover:text-primary transition duration-200">
                <FaPhoneAlt className="mr-2" />
                <a href="tel:+977015244242" className="hover:underline">
                  +977-01-5244242
                </a>
              </li>
              <li className="flex items-start hover:text-primary transition duration-200">
                <FaMapMarkerAlt className="mr-2 mt-1" />
                <a
                  href="https://maps.app.goo.gl/RkCTqdBNCYzkcAaL9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  Nirdhan Utthan Building, Bhagawati Bahal 01
                </a>
              </li>
            </ul>
          </div>

          {/* Trusted By */}
          <div>
            <h2 className="font-bold text-secondary mb-2 text-base  text-md md:text-lg lg:text-xl">
              Trusted By
            </h2>
            <ul className="space-y-1 font-light text-sm sm:text-md lg:text-base">
              <li>NIC Asia Laghubitta</li>
              <li>NMB Laghubitta</li>
              <li>DEPROSC Laghubitta</li>
              <li>Forward MicroFinance</li>
              <li>Mahuli Laghubitta</li>
              <li>SKBBL</li>
              <li>Swabalamban Laghubitta</li>
              <li>Nepal Rastra Bank</li>
              <li>Embassy of India, Kathmandu</li>
            </ul>
          </div>

          {/* Query Form */}
          <div>
            <h2 className="font-bold text-secondary mb-4 text-base sm:text-lg">
              Do You Have Any Queries?
            </h2>
            <form className="space-y-4 w-full" onSubmit={handelSubmit}>
              <input
                type="text"
                placeholder="Enter your Full Name"
                className="w-full p-3 rounded-lg text-sm text-gray-800 border border-gray-300 focus:border-white focus:ring-0"
              />
              <input
                type="email"
                placeholder="Enter Your Email"
                className="w-full p-3 rounded-lg text-sm text-gray-800 border border-gray-300 focus:border-white focus:ring-0"
              />
              <textarea
                placeholder="Enter your message"
                className="w-full p-3 rounded-lg text-sm text-gray-800 border border-gray-300 focus:border-white focus:ring-0"
                rows="4"
              ></textarea>
              <button
                type="submit"
                className="h-10 px-5 text-white bg-secondary w-full rounded-lg hover:bg-blue-900 text-sm"
              >
                Submit
              </button>
            </form>
          </div>
        </div>

        {/* Social Icons */}
        <div className="flex flex-wrap justify-center space-x-6 mt-6">
          <a
            href="https://www.facebook.com/uranustech/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FaFacebookF className="text-xl text-secondary cursor-pointer hover:text-white" />
          </a>
          <a
            href="https://np.linkedin.com/company/uranustechnepal"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FaLinkedinIn className="text-xl text-secondary cursor-pointer hover:text-white" />
          </a>
        </div>

        {/* Footer Bottom */}
        <hr className="my-10 border-secondary" />
        <div className="text-center text-white text-sm sm:text-base">
          <p>© 2024 Uranus Tech Nepal Pvt. Ltd. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
