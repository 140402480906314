import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";

const AlertDialog = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      <div className="bg-white rounded-xl shadow-xl p-6 w-96 max-w-sm">
        <div className="flex items-center">
          <FaExclamationTriangle className="text-yellow-500 text-3xl mr-4" />
          <h2 className="text-xl font-semibold text-gray-800">Alert</h2>
        </div>
        <p className="mt-4 text-gray-600">{message}</p>
        <div className="mt-6 flex justify-end">
          <button
            onClick={onClose}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-6 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 transition-all"
          >
            OK!
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertDialog;
