import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import logo from "../assets/logo.png";
import { motion, AnimatePresence } from "framer-motion";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleButton = () => {
    setMenuOpen(!menuOpen);
  };

  const getNavLinkClass = ({ isActive }) =>
    `font-light hover:cursor-pointer transition-colors duration-200 ${
      isActive
        ? "text-primary font-bold"
        : "text-secondaryText hover:text-primary"
    }`;

  return (
    <div className="sticky top-0 z-40 w-full bg-white shadow">
      {/* Navbar */}
      <nav className="bg-white flex items-center justify-between px-5 py-3">
        {/* Logo */}
        <NavLink to="/" className="flex items-center space-x-3">
          <img
            src={logo}
            className="h-10 md:h-14 lg:h-16 transition-transform duration-300"
            alt="Uranus Logo"
          />
        </NavLink>

        {/* Desktop Links */}
        <div className="hidden sm:flex text-xs md:text-sm lg:text-base space-x-6">
          <NavLink to="/" className={getNavLinkClass}>
            Home
          </NavLink>
          <NavLink to="/products" className={getNavLinkClass}>
            Our Portfolio
          </NavLink>
          <NavLink to="/about" className={getNavLinkClass}>
            About Us
          </NavLink>
          <NavLink to="/careers" className={getNavLinkClass}>
            Careers
          </NavLink>
        </div>

        {/* Sign In Button */}
        <div className="hidden sm:flex">
          <NavLink
            to="/login"
            className={`flex items-center px-3 py-1 gap-2 border border-primary text-sm font-medium rounded-full transition-colors duration-200 ${
              location.pathname === "/login"
                ? "bg-primary text-white"
                : "text-primary hover:bg-primary hover:text-white"
            }`}
          >
            <SupervisedUserCircleIcon
              className={`${
                location.pathname === "/login" ? "text-white" : ""
              }`}
            />
            <span>Sign In</span>
          </NavLink>
        </div>

        {/* Mobile Menu Toggle */}
        <div
          className="sm:hidden text-2xl cursor-pointer"
          onClick={toggleButton}
        >
          <i className={`fa ${menuOpen ? "fa-times" : "fa-bars"}`}></i>
        </div>
      </nav>

      {/* Mobile Fullscreen Menu */}
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            className="fixed inset-0 bg-gradient-to-br from-white to-blue-50 z-40 flex flex-col px-5 py-8 space-y-6 shadow-lg"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "tween", duration: 0.3, ease: "easeInOut" }}
          >
            {/* Close Button */}
            <div className="flex justify-between items-center">
              <a href="/" className="flex items-center">
                <img src={logo} className="h-10" alt="Uranus Logo" />
              </a>
              <motion.i
                className="fa fa-times text-2xl cursor-pointer text-gray-700 hover:text-red-500"
                onClick={toggleButton}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              ></motion.i>
            </div>

            {/* Menu Items with Staggered Animation */}
            <motion.div className="flex flex-col space-y-6 border-b-2 border-gray-200 pb-6">
              {[
                {
                  to: "/",
                  icon: "fa-home",
                  text: "Home",
                  color: "text-blue-600",
                },
                {
                  to: "/products",
                  icon: "fa-briefcase",
                  text: "Our Portfolio",
                  color: "text-purple-900",
                },
                {
                  to: "/about",
                  icon: "fa-info-circle",
                  text: "About Us",
                  color: "text-green-600",
                },
                {
                  to: "/careers",
                  icon: "fa-users",
                  text: "Careers",
                  color: "text-orange-600",
                },
                {
                  to: "/login",
                  icon: "fa-sign-in",
                  text: "Sign In",
                  color: "text-indigo-600",
                },
              ].map((item, index) => (
                <motion.div
                  key={item.to}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 + index * 0.05 }}
                >
                  <NavLink
                    to={item.to}
                    className={`flex items-center gap-3 text-lg font-medium text-gray-700 hover:text-primary transition-colors duration-200`}
                    onClick={toggleButton}
                  >
                    <i className={`fa ${item.icon} ${item.color}`}></i>
                    {item.text}
                  </NavLink>
                </motion.div>
              ))}
            </motion.div>

            {/* Contact Info for Mobile with Fade In */}
            <motion.div
              className="mt-6 text-sm bg-blue-50 py-2 px-4 rounded-lg shadow-sm"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <div className="flex items-center gap-2 text-gray-700">
                <LocalPhoneIcon className="text-blue-500" />
                <span>01-5244242</span>
              </div>
              <div className="flex items-center gap-2 mt-2 text-gray-700">
                <EmailIcon className="text-blue-500" />
                <span>info@uranustechnepal.com</span>
              </div>
            </motion.div>

            {/* Social Media Links */}
            {/* <motion.div
              className="flex justify-center gap-4 mt-4"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <a
                href="#"
                className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors"
              >
                <i className="fa fa-facebook"></i>
              </a>
              <a
                href="#"
                className="w-8 h-8 flex items-center justify-center rounded-full bg-sky-500 text-white hover:bg-sky-600 transition-colors"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                href="#"
                className="w-8 h-8 flex items-center justify-center rounded-full bg-red-500 text-white hover:bg-red-600 transition-colors"
              >
                <i className="fa fa-youtube"></i>
              </a>
              <a
                href="#"
                className="w-8 h-8 flex items-center justify-center rounded-full bg-pink-500 text-white hover:bg-pink-600 transition-colors"
              >
                <i className="fa fa-instagram"></i>
              </a>
            </motion.div> */}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navbar;
