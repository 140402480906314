import hrmimg from "../assets/hrm.png";
import mPassbook from "../assets/mpassbook.png";
import dfa from "../assets/dfaimage.png";
import uranusSMS from "../assets/urnaussms.png";
import dfaimage from "../assets/dfaimage.png";
import acsic from "../assets/Acsic.png";
import risuCare from "../assets/risuCare.png";
import mpassbook from "../assets/mpassbook.png";
import bankSoftware from "../assets/bankSoftware.png";
import nrb from "../assets/nrb.png";
import embassy from "../assets/embassy.png";
const projectData = [
  {
    CBS: {
      name: "Microfinance Banking Solution",
      imgUrl:
        "https://uranus-tech.com/static/media/cbs.8d7f9e27839d4d793250.png",
      description:
        "Our Core Banking Software (CBS) is a robust solution designed to meet the diverse needs of microfinance institutions, cooperatives, and other financial entities. It features a flexible, multi-tiered architecture that supports high availability and scalability. Modules can be distributed across servers to enhance capacity and performance. Our solution offers an all-in-one platform to manage your microfinance operations efficiently.",
      shortFeatures: [
        "Center and Group Management",
        "KYC Management",
        "Transaction Management",
      ],
      longFeatures: [
        "Collection Sheet Handling: Efficient processing and handling of microfinance collection sheets.",
        "Loan Management: Post-disbursement management, including follow-up, recovery, overdue, and NPL management.",
        "Reporting: Generate and manage reports for overdue loans, NPL, and booking.",
        "Rescheduling and Restructuring: Manage loan rescheduling, restructuring, and renewals.",
        "Voucher and Borrowing Management: Handle vouchers, external and internal borrowings, and budget management.",
        "Budget and Target Management: Track and manage budgets and financial targets.",
        "Fixed Assets and Financial HRM: Manage fixed assets and human resources with integrated systems.",
        "Internal Messaging: Facilitate communication with an internal messaging system.",
        "Branch Management: Oversee multiple branches efficiently.",
      ],
    },

    DFA: {
      name: "Digital Field Assistant",
      imgUrl: dfa,
      description:
        "DFA revolutionizes field operations by replacing manual process with digital efficiency which includes collection sheet handling, KYC management, loan requests, disbursements, and staff tracking.",
      shortFeatures: [
        "Replace Manual Collection Sheets",
        "KYC Management",
        "Handle Loans Efficiently",
      ],
      longFeatures: [
        "Staff Tracker: Manage attendance and leave with ease.",
        "Replace Manual Collection sheets: Leave messy paper collection sheet behind and switch to our easy digital collection system.",
        "Loan Management: Post-disbursement management, including follow-up, recovery, overdue, and NPL management.",
        "Staff Tracker: Manage attendance and leave with ease.",
        "Branch Management: Oversee multiple branches efficiently.",
        "Voucher and Borrowing Management: Handle vouchers, external and internal borrowings, and budget management.",
        "Budget and Target Management: Track and manage budgets and financial targets.",
        "Fixed Assets and Financial HRM: Manage fixed assets and human resources with integrated systems.",
        "Internal Messaging: Facilitate communication with an internal messaging system.",
      ],
    },

    mPassbook: {
      name: "Digital Passbook for Customers",
      imgUrl: mPassbook,
      description:
        "The ultimate digital passbook solution that enhances financial record-keeping and provides convenience.",
      shortFeatures: [
        "Manage Accounts Digitally",
        "Real-time Balance",
        "Eco-Friendly (Paperless)",
      ],
      longFeatures: [
        "Account Information: With mPassbook, you can easily track your savings, deposits, withdrawals, and balance in real time from your mobile device.",
        "Replacement of Manual Passbook: Say Goodbye to paper passbooks with mPassbook. Our digital solution replaces the old manual system, giving you a more convenient and eco-friendly way to track your finances.",
        "Customer Support: Submit and track complaints easily with mPassbook. Stay updated on their status and get quick resolutions for a smoother experience.",
        "Detailed Loan Information: Easily manage your loans with mPassbook. Check balances, repayment schedules, and interest rates anytime.",
        "Pay Bills in One Place: Pay all your bills in one place without multiple transactions.",
        "Book Flights: Book flights directly through the app for hassle-free travel.",
        "QR Code for Secure Payments: Use a QR code for quick and secure payments.",
        "Fund Transfer Between Banks: Transfer funds between banks effortlessly and securely.",
      ],
    },

    UranusSMS: {
      name: "Uranus SMS Solution",
      imgUrl: uranusSMS,
      description:
        "Keep your clients informed with our SMS portal, offering transaction alerts, follow-up notifications, and customizable message services.",
      shortFeatures: ["Push SMS Service", "Follow-up Alerts"],
      longFeatures: [
        "Transaction Alerts: Notify customers and staff of account activity in real-time.",
        "Custom Messages: Send custom SMS campaigns to staff or clients.",
      ],
    },

    UranusHRM: {
      name: "UranusHRM - Human Resource Management Software",
      imgUrl: hrmimg,
      description:
        "Uranus HRM simplifies and enhances HR processes with features tailored to modern organizations. It ensures efficient management of your workforce and seamless integration with other systems.",
      shortFeatures: [
        "Time & Shift Management",
        "Payroll & Tax Compliance",
        "Employee Management",
      ],
      longFeatures: [
        "Automated Time Tracking: Record working hours accurately.",
        "Shift Scheduling: Easily create and adjust shifts.",
        "Real-Time Reporting: Generate and view attendance and shift data instantly.",
        "Overtime Calculation: Automatically track and calculate overtime.",
        "Salary Processing: Automate salary calculations and timely payroll distribution.",
        "Leave Management: Sync leave with payroll for accurate deductions.",
        "Tax & Compliance: Automatically calculate and deduct taxes based on local regulations.",
        "Appraisal Integration: Combine performance data with financial metrics.",
        "Feedback and Development: Provide feedback and career growth opportunities.",
        "Onboarding & Offboarding: Simplify new hires and departures.",
        "Attendance Tracking: Monitor attendance with various methods.",
      ],
    },

    FAMIS: {
      name: "FAMIS-Fixed Assets Management Information System",
      imgUrl:
        "https://newsroom.gy/wp-content/uploads/2016/08/pension_14735595_07_6_1469c.jpg",
      description:
        "FAMIS simplifies and enhances how you handle your assets, from acquisition to disposal. Maintain accurate records and optimize your asset usage.",
      shortFeatures: [
        "Asset Categorization",
        "Purchase & Disposal",
        "Depreciation Management",
      ],
      longFeatures: [
        "Fixed Assets Category & Subcategory: Organize your assets into detailed categories and subcategories for better tracking and reporting.",
        "Vendor Listing: Maintain an up-to-date list of vendors for streamlined procurement and support.",
        "Sale: Manage asset sales effortlessly, including all financial transactions.",
        "Maintenance: Schedule and track asset maintenance to extend lifespan.",
        "Depreciation Book: Automatically calculate and maintain depreciation records.",
        "Realization Methods: Support for Reducing Balance, NAS, and Straight-line depreciation methods.",
      ],
    },

    UCare: {
      name: "Uranus Disability / Old Age Care Management Software",
      imgUrl: risuCare,
      description:
        "Ura Care streamlines operations, enhances efficiency, and ensures exceptional care delivery for disability and old age care.",
      shortFeatures: [
        "Human Resource Supply Chain",
        "Enrollment of Hourly Workers",
        "Payroll & Shift Management",
      ],
      longFeatures: [
        "Time Allocation/De-Allocation: Efficiently schedule and adjust shifts in alignment with client needs.",
        "Agreement Management: Tools to create, manage, and track agreements with clarity and compliance.",
        "Tax Calculation Compliance: Accurate tax calculations based on the Australian Tax Labor Act.",
      ],
    },

    IMS: {
      name: "Insurance Management-Loan (Non-Life) Insurance Management Software for Banks",
      imgUrl:
        "https://www.damcogroup.com/insurance/wp-content/themes/insurance/img/product/insure-edge/insurance-software.jpg",
      description:
        "Designed to streamline the administration of non-life insurance loans, making the process simpler and more effective for banks.",
      shortFeatures: ["Deduction & Schedule Setup", "Eligibility Test"],
      longFeatures: [
        "Insurance Follow-up: Track and manage insurance follow-ups to ensure compliance and updates.",
        "Frequency-Based Deduction: Customize and manage insurance deductions based on various frequencies.",
      ],
    },

    DPMS: {
      name: "Daily Progress Management Software for Bank",
      imgUrl: bankSoftware,
      description:
        "DPMS is the smart solution for managing your bank’s daily operations, simplifying workflows and providing clear insights into organizational performance.",
      shortFeatures: [
        "Easy Parameter Setup",
        "Smooth Data Integration",
        "Staff Target Tracking",
      ],
      longFeatures: [
        "Clear Reporting with Visual Charts: Stay informed with built-in bar, line, and pie charts.",
        "Monitor staff performance with an easy-to-use target and achievement system.",
      ],
    },

    PDS: {
      name: "Pension Disbursement Software for Embassy of India",
      imgUrl: embassy,
      description:
        "PDS handles complex calculations required for pension management, ensuring timely and accurate pension disbursement for military personnel.",
      shortFeatures: ["Accurate Pension Calculations", "Provision Management"],
      longFeatures: [
        "Smooth Disbursement: Ensures payments are processed efficiently and on time for Gurkha Regiment, Assam Rifles, Indian Army, etc.",
      ],
    },

    ITSS: {
      name: "International Trade Statistic Software for Nepal Rastra Bank",
      imgUrl: nrb,
      description:
        "A cutting-edge software solution providing Nepal Rastra Bank with insights into international trade from India, China, and other key countries.",
      shortFeatures: [
        "Comprehensive Trade Statistics",
        "Risk Management Tools",
      ],
      longFeatures: [
        "Data-Driven Insights: Empower policymakers to make informed decisions, manage risks effectively, and foster economic growth.",
      ],
    },

    ACSIC: {
      name: "Conference Management Web Solution with Mobile App",
      imgUrl: acsic,
      description:
        "A web and mobile solution for managing conferences organized by ACSIC.",
      shortFeatures: [
        "Event Scheduling",
        "Attendee Registration",
        "Mobile App Integration",
      ],
      longFeatures: [
        "Session Management: Create, update, and track conference sessions seamlessly.",
        "Speaker Profiles & Bios: Highlight speaker credentials and topics.",
        "Notifications & Reminders: Keep attendees in the loop with timely updates.",
      ],
    },
  },
];

export default projectData;
