import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaUsers, FaProjectDiagram, FaClock } from "react-icons/fa";
import ceoimg from "../assets/ceo_ura.png";
import cooimg from "../assets/nirajan.png";
import groupPhoto from "../assets/groupPhotoEmployee.jpg";
import groupPhotoLarge from "../assets/groupPhotoLarge.JPG";
import support from "../assets/support.jpg";
import workingTeam from "../assets/workingTeam.JPG";
import Footer from "../components/Footer";

// Counter component that animates from 0 to target value
const AnimatedCounter = ({ value, label, icon }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const duration = 2000; // 2 seconds animation
    const frameDuration = 1000 / 60; // 60fps
    const totalFrames = Math.round(duration / frameDuration);
    let frame = 0;

    const counter = setInterval(() => {
      frame++;
      const progress = frame / totalFrames;
      const currentCount = Math.floor(progress * value);

      if (frame === totalFrames) {
        setCount(value);
        clearInterval(counter);
      } else {
        setCount(currentCount);
      }
    }, frameDuration);

    return () => clearInterval(counter);
  }, [value]);

  return (
    <div className="flex flex-col items-center p-4 sm:p-6">
      {icon}
      <div className="text-3xl sm:text-4xl font-bold text-gray-700 mt-2">
        {count} +
      </div>
      <div className="text-gray-600 text-center mt-2">{label}</div>
    </div>
  );
};

const AboutUs = () => {
  // Stats data
  const stats = [
    {
      value: 100,
      label: "Happy Clients",
      icon: <FaUsers className="text-3xl sm:text-4xl text-blue-500" />,
    },
    {
      value: 50,
      label: "Projects Completed",
      icon: (
        <FaProjectDiagram className="text-3xl sm:text-4xl text-green-500" />
      ),
    },
    {
      value: 12,
      label: "Years Experience",
      icon: <FaClock className="text-3xl sm:text-4xl text-orange-500" />,
    },
  ];

  return (
    <div className=" bg-gray-100">
      <div
        className=" max-w-7xl px-4 md:px-10 lg:px-20 mx-auto min-h-screen  md:pt-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.div
          className="bg-gradient-to-bl from-secondary to-primary rounded-3xl text-white text-center py-12 px-4 shadow-lg mb-12 relative overflow-hidden"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {/* Texture Background Layer */}
          <div
            className="absolute inset-0 bg-cover bg-center opacity-15 rounded-3xl z-0 "
            style={{
              backgroundImage: `url(${groupPhotoLarge})`,
            }}
          />

          <div className="relative z-10">
            {" "}
            <motion.h1
              className="text-4xl md:text-5xl font-bold mb-4"
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              About Us
            </motion.h1>
            <motion.p
              className="text-lg md:text-xl text-blue-100 w-full mx-auto "
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
            >
              Explore our principles and why we are committed to making a
              difference.
            </motion.p>
          </div>
        </motion.div>

        {/* Hero Section */}
        <motion.div
          className=" w-full px-4  md:pt-7  "
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className=" flex flex-col lg:flex-row items-center gap-4 ">
            <motion.div
              className="w-full lg:w-1/2 mb-6 lg:mb-0"
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.6 }}
            >
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-gray-700 text-center lg:text-start ">
                Our Journey
              </h1>
              <motion.div
                className="w-full lg:w-1/2 relative"
                initial={{ x: 50, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.6 }}
              >
                <div className="relative h-56 sm:h-64 md:h-72 lg:h-96 rounded-lg overflow-hidden md:hidden">
                  <img
                    src={groupPhotoLarge}
                    alt="Our Team"
                    className="w-full h-full object-cover lg:max-h- "
                  />
                  <div className="absolute -bottom-6 -right-6 w-16 sm:w-24 h-16 sm:h-24 bg-primary rounded-full" />
                </div>
              </motion.div>
              <p className="mt-4 sm:mt-5 text-base sm:text-lg md:text-xl text-gray-500 text-center md:text-start">
                At Uranus Tech Nepal, our company and culture mirror the
                craftsmanship of our products. We don’t just code; we create
                experiences designed to delight. Since our founding in 2013,
                we’ve become a leading provider of cloud-based core banking
                software for Nepal’s microfinance institutions and cooperatives.
                Our innovative solutions streamline financial operations and
                management, enabling institutions to better serve millions of
                customers nationwide.
              </p>
            </motion.div>

            <motion.div
              className="w-full lg:w-1/2 relative hidden md:block"
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.6 }}
            >
              <div className="relative max-h-64 sm:max-h-72 md:max-h-80 lg:max-h-96 rounded-lg overflow-hidden mt-2">
                <img
                  src={groupPhoto}
                  alt="Our Team"
                  className="w-full h-full object-cover "
                />
                <div className="absolute -bottom-6 -right-6 w-16 sm:w-20 md:w-24 h-16 sm:h-20 md:h-24 bg-primary rounded-full" />
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* Our Mission */}
        <motion.div
          className="py-8 sm:py-10 w-full   px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.6 }}
        >
          <div className=" flex flex-col lg:flex-row-reverse items-center">
            <div className="w-full lg:w-1/2 mb-6 lg:mb-0 lg:pl-8">
              <h2 className="text-2xl sm:text-3xl font-extrabold text-center lg:text-start text-gray-700">
                Our Mission
              </h2>
              <p className="mt-3 sm:mt-4 text-base sm:text-lg text-gray-500">
                We believe not just in growing bigger, but in growing better.
                And growing better means aligning the success of our own
                business with the success of our customers. Whether it's Core
                Banking Solutions, Web Development, Mobile App Development, or
                IT Consulting, we're committed to delivering solutions that
                drive success for our clients.
              </p>
            </div>

            <div className="w-full lg:w-1/2 relative">
              <div className="rounded-lg overflow-hidden">
                <img
                  src={support}
                  alt="Mission"
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
          </div>
        </motion.div>

        {/* Stats Counter */}
        <motion.div
          className=" w-full py-8 sm:py-10 px-4 "
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.6 }}
        >
          <div className="">
            <div className="text-center mb-6 sm:mb-10">
              <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-700">
                Our Impact
              </h2>
              <p className="mt-3 sm:mt-4 text-base sm:text-lg text-gray-500">
                The numbers that drive our passion
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-8 md:gap-14">
              {stats.map((stat, index) => (
                <div className="cursor-pointer hover:scale-110 transform transition-all duration-500">
                  <motion.div
                    key={index}
                    className="bg-gray-50 rounded-lg shadow-sm transition-shadow duration-300 "
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.8 + index * 0.1, duration: 0.4 }}
                  >
                    <AnimatedCounter
                      value={stat.value}
                      label={stat.label}
                      icon={stat.icon}
                    />
                  </motion.div>
                </div>
              ))}
            </div>
          </div>
        </motion.div>

        {/* Our Story */}
        <motion.div
          className="py-8 sm:py-10  w-full   px-4"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.6 }}
        >
          <div className=" flex flex-col lg:flex-row-reverse items-center">
            <div className="w-full lg:w-1/2 mb-6 lg:mb-0 lg:pl-8">
              <h2 className="text-2xl sm:text-3xl font-extrabold text-center lg:text-start text-gray-700">
                Our Story
              </h2>
              <p className="mt-3 sm:mt-4 text-base sm:text-lg text-gray-500">
                Along the way, we expanded beyond developing into a crafted, not
                coded suite of products that creates the frictionless customer
                experience that buyers expect today. By providing innovative
                solutions, Uranus Tech Nepal is transforming how businesses
                operate. Our team of experts is dedicated to delivering
                customized solutions that cater to the specific needs of our
                clients, ensuring they stay ahead in the competitive market.
              </p>
            </div>

            <div className="w-full lg:w-1/2 relative">
              <div className="rounded-lg overflow-hidden">
                <img
                  src={workingTeam}
                  alt="Our Story"
                  className="w-full h-full lg:max-h-96  object-cover"
                />
              </div>
            </div>
          </div>
        </motion.div>

        {/* Leadership */}
        <motion.div
          className="w-full py-8 sm:py-10 px-4 "
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.2, duration: 0.6 }}
        >
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-6 sm:mb-8">
              <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-700">
                Leadership
              </h2>
              <p className="mt-3 sm:mt-4 text-base sm:text-lg text-gray-500">
                Meet the Leadership behind our success
              </p>
            </div>

            <div className="grid grid-cols-1 gap-6 sm:gap-8 lg:gap-10 lg:grid-cols-2">
              {/* Executive Director Card */}
              <motion.div
                className="bg-gray-50 rounded-lg shadow-lg p-4 sm:p-6 flex flex-col md:flex-row items-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.3, duration: 0.5 }}
              >
                <div className="w-28 h-28 sm:w-32 sm:h-32 md:w-36 md:h-36 lg:w-40 lg:h-40 rounded-full overflow-hidden mb-4 md:mb-0 md:mr-4 lg:mr-6 flex-shrink-0 ">
                  <img
                    src={ceoimg}
                    alt="Executive Director"
                    className="w-full h-full object-cover "
                  />
                </div>
                <div>
                  <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-1 sm:mb-2 text-center md:text-left">
                    Mr. Nirajan Subedi
                  </h3>
                  <p className="text-base sm:text-lg text-gray-600 mb-2 sm:mb-3 text-center md:text-left">
                    Executive Director of Uranus Tech Nepal
                  </p>
                  <p className="italic text-sm sm:text-base text-gray-600">
                    "At Uranus Tech Nepal, we believe that innovation and
                    customer satisfaction go hand in hand. Our dedicated team is
                    committed to delivering solutions that not only meet but
                    exceed expectations. We are always looking ahead,
                    anticipating challenges, and finding new ways to create
                    value for our clients. Thank you for trusting us with your
                    technological needs."
                  </p>
                </div>
              </motion.div>

              {/* COO Card */}
              <motion.div
                className="bg-gray-50 rounded-lg shadow-lg p-4 sm:p-6 flex flex-col md:flex-row items-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1.4, duration: 0.5 }}
              >
                <div className="w-28 h-28 sm:w-32 sm:h-32 md:w-36 md:h-36 lg:w-40 lg:h-40 rounded-full overflow-hidden mb-4 md:mb-0 md:mr-4 lg:mr-6 flex-shrink-0">
                  <img
                    src={cooimg}
                    alt="COO"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-1 sm:mb-2 text-center md:text-left">
                    Mr. Homnath Rijal
                  </h3>
                  <p className="text-base sm:text-lg text-gray-600 mb-2 sm:mb-3 text-center md:text-left">
                    COO & Business Development Officer of Uranus Tech Nepal
                  </p>
                  <p className="italic text-sm sm:text-base text-gray-600">
                    "Our focus on operational excellence ensures that we
                    consistently deliver on our promises. At Uranus Tech Nepal,
                    we are committed to driving efficiency and fostering
                    innovation across all aspects of our business. By aligning
                    our operations with our clients' goals, we create strategies
                    that not only meet but exceed expectations."
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutUs;
